


































































































































































import { Component, Prop } from "vue-property-decorator";
import { email, integer, minValue, required } from "vuelidate/lib/validators";
import ErrorMessageHandlerMixin from "@/misc/ErrorMessageHandler.mixins";
import { validationMixin } from "vuelidate";
import { mixins } from "vue-class-component";
import User from "@/models/User.model";
import { Gender } from "@/enum/UserTitle.enum";
import { UserDegree } from "@/enum/UserDegree";
import { AUTH_STORE_NAME } from "@/store/auth.store";
import { namespace } from "vuex-class";
import IconTooltipComponent from "@/components/IconTooltip.component.vue";
import { UserType } from "@/enum/UserType.enum";
import Application from "@/models/Application";
import { APPLICATION_STORE_NAME, ApplicationStoreActions } from "@/store/application.store";
import App from "@/App.vue";

const AuthStore = namespace(AUTH_STORE_NAME);
const ApplicationStore = namespace(APPLICATION_STORE_NAME);

@Component({
  components: {
    IconTooltip: IconTooltipComponent,
    DuplicatesList: () =>
      import(
        "@/components/Duplicates.list.component.vue"
        ),
  },
})
export default class DuplicatesComponent extends mixins(ErrorMessageHandlerMixin) {
  @Prop({ default: Application.parseFromObject({}) })
  public application!: Application;

  @ApplicationStore.Action(ApplicationStoreActions.CHECK_DUPLICATES)
  private checkDuplicates!: (payload: {
    user: User
  }) => Promise<any | null>;

  private user: User = User.parseFromObject({});

  private showDuplicatedList: boolean = false;

  private showDuplicationInfo: boolean = true;

  private onShowDuplicationInfo(){
    this.showDuplicationInfo = true;
  }

  private onHideDuplicationInfo(){
    this.showDuplicationInfo = false;
  }
  private onShowDuplicatedList(){
    this.showDuplicatedList = true;
  }

  private onHideDuplicatedList(){
    this.showDuplicatedList = false;
  }


  public created() {
    this.user = User.parseFromObject({...this.application.user});
  }

  private get isChangingEmail() {
    return this.application.user.email !== this.user.email;
  }

  private get isCustomer() {
    return this.user.type === UserType.CUSTOMER;
  }

  /**
   * user model that is used on the inputs for storing the values
   * @private
   */

  /**
   * different degreeOptions
   * @private
   */
  private dOptions = [UserDegree.NONE, UserDegree.DR, UserDegree.PROF, UserDegree.PHD, UserDegree.BA, UserDegree.MBA, UserDegree.PHD, UserDegree.PROFDR];

  /**
   * different gender options
   * @private
   */
  private gOptions = [Gender.MALE, Gender.FEMALE, Gender.DIVERSE, Gender.NOT_SET];

  /**
   * returns translated genders based on the gender enum
   * @private
   */
  private get genderOptions() {
    return this.gOptions.map((n: Gender) => this.$t('GENERAL.GENDER.' + n));
  }

  /**
   * flag that shows and hides the chars of the password field
   */
  private hidePassword: boolean = true;

  /**
   * Loading State bool
   * @private
   */
  private isLoading: boolean = false;

  /**
   * Check if Form is Valid
   * if valid try to Register a new Account and listen for API Responses
   * If Invalid return so Vuelidate handles Errors first
   * @private
   */
  private async changeUserInfo() {
    // Form is Valid try to Register a new Account
    try {
      // Set Loading to true
      this.isLoading = true;
      // logs in the user, checks if the user has the correct role
      await this.checkDuplicates({user: this.application.user});
      this.onShowDuplicatedList();
      this.onHideDuplicationInfo();
    } catch (e) {
      this.$handleError(e, () => {
        switch (e.status) {
          default:
            this.$notifyErrorSimplified("GENERAL.NOTIFICATIONS.ERROR_GENERAL");
        }
      });
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * is called when the dialog should be dismissed, calls close dialog action
   * @param reload
   * @private
   */
  private dismiss(reload: boolean = false) {
    this.$emit('closeDialog', reload);
  }

  private get isCompany(): boolean {
    return this.user.type === UserType.COMPANY;
  }
}
